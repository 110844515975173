var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-edit-workspace",attrs:{"id":"modal-edit-workspace","centered":"centered","title":(_vm.workspace.id ? 'Modifier': 'Ajouter') + " un espace de travail"},on:{"ok":_vm.create},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('div',{staticClass:"w-100"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":function($event){return ok()}}},[_vm._v(_vm._s((_vm.workspace.id ? 'Modifier': 'Ajouter')))]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right mr-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){return cancel()}}},[_vm._v("Annuler")])],1)]}}])},[(_vm.isCreatingWorkspace)?_c('div',[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Chargement...")])]),_c('br'),_vm._v("Chargement des détails... ")])]):_c('validation-observer',{ref:"formEdittWorkspace"},[_c('b-row',[_c('b-col',{staticClass:"d-fxlex mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"},attrs:{"label":"Nom *","label-for":"socialReason"}},[_c('validation-provider',{attrs:{"name":"Nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"not-autocomplete",attrs:{"id":"socialReason","state":errors.length > 0 ? false : null,"autocomplete":"nope","aria-autocomplete":"nope"},model:{value:(_vm.workspace.socialReason),callback:function ($$v) {_vm.$set(_vm.workspace, "socialReason", $$v)},expression:"workspace.socialReason"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"d-flex flex-column align-content-center align-items-center",attrs:{"label":"Couleur","label-for":"color"}},[_c('validation-provider',{attrs:{"name":"Color"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-swatches',{attrs:{"state":errors.length > 0 ? false : null,"show-fallback":"","popover-x":"left","id":"color","show-border":false,"swatch-size":32,"trigger-style":{ width: '32px', height: '32px', borderRadius: '50%',padding: '0px' },"swatch-style":{ width: '32px', height: '32px', borderRadius: '50%',padding: '0px' },"wrapper-style":{ paddingLeft: '8px', paddingRight: '0px' }},model:{value:(_vm.workspace.color),callback:function ($$v) {_vm.$set(_vm.workspace, "color", $$v)},expression:"workspace.color"}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }