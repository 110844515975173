<template>
    <b-modal id="modal-edit-workspace" ref="modal-edit-workspace" centered="centered" :title="(workspace.id ? 'Modifier': 'Ajouter') + ` un espace de travail`" @ok="create">
        <div v-if="isCreatingWorkspace">
            <div class="text-center">
                <div class="spinner-border text-primary" role="status"><span class="sr-only">Chargement...</span></div><br />Chargement des détails...
            </div>
        </div>
        <validation-observer v-else ref="formEdittWorkspace">
            <b-row>
                <b-col cols="12" class="d-fxlex mb-1">
                    <b-form-group label="Nom *" label-for="socialReason" class="pr-1" style="flex:1">
                        <validation-provider #default="{ errors }" name="Nom" rules="required">
                        <b-form-input
                            id="socialReason"
                            v-model="workspace.socialReason"
                            :state="errors.length > 0 ? false : null"
                            class="not-autocomplete"
                            autocomplete="nope" aria-autocomplete="nope"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label="Couleur" label-for="color" class="d-flex flex-column align-content-center align-items-center">
                        <validation-provider #default="{ errors }" name="Color">
                        <v-swatches
                            :state="errors.length > 0 ? false : null" 
                            v-model="workspace.color"
                            show-fallback
                            popover-x="left"
                            id="color"
                            :show-border="false"
                            :swatch-size="32"
                            :trigger-style="{ width: '32px', height: '32px', borderRadius: '50%',padding: '0px' }"
                            :swatch-style="{ width: '32px', height: '32px', borderRadius: '50%',padding: '0px' }"
                            :wrapper-style="{ paddingLeft: '8px', paddingRight: '0px' }"
                        ></v-swatches> 
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
        </validation-observer><template #modal-footer="{ ok, cancel }">
            <div class="w-100">
                <!-- <b-button class="float-left" v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-if="workspace.id" variant="outline-danger" @click="ok()">Supprimer</b-button> -->
                <b-button class="float-right" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="ok()">{{(workspace.id ? 'Modifier': 'Ajouter')}}</b-button>
                <b-button class="float-right mr-1" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="cancel()">Annuler</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  defineRule,
  configure,
  localize,
} from "vee-validate";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      workspace: {
        socialReason: "",
        color: "#0c3571",
      },
    };
  },
  methods: {
    popupCreateWorkspace(id){
      if(id!==0){
        this.fetchWorkspace(id).then(res => {
          this.workspace = res
        })
      }else this.initializeForm()
    },
    initializeForm() {
      this.workspace = {
        socialReason: "",
        color: "#0c3571",
      };
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formEdittWorkspace.validate().then((success1) => {
        if (success1) {
          if(this.workspace.id) this.updateWorkspace(this.workspace)
          else this.createWorkspace(this.workspace);
          this.$nextTick(() => {
            this.$refs["modal-edit-workspace"].toggle("#toggle-btn");
          });
        }
      });
    },
    ...mapActions(["createWorkspace", "updateWorkspace", "fetchWorkspace"]),
  },
  computed: {
    ...mapGetters(["isCreatingWorkspace"]),
  },
  
  components: {
    ValidationProvider,
    ValidationObserver,
    VSwatches,
  },
  directives: {
    Ripple,
  },
};
</script>

<style>
</style>